<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <ValidationProvider name="Crop Type" vid="crop_type_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="crop_type_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('seedsConfig.cropType')}} <span class="text-danger">*</span>
                        </template>
                         <b-form-select
                        plain
                        v-model="varietyData.crop_type_id"
                        :options="CropTypeList"
                        id="crop_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Crop Name" vid="crop_name_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="crop_name_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('seedsConfig.cropName')}} <span class="text-danger">*</span>
                        </template>
                         <!-- <v-select name="croptypeID"
                          v-model="varietyData.crop_name"
                          label="text"
                          :options= CropNameList
                          :state="errors[0] ? false : (valid ? true : null)"
                          /> -->
                         <b-form-select
                        plain
                        v-model="varietyData.crop_name_id"
                        :options="CropNameList"
                        id="crop_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Variety Name" vid="variety_name" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="variety_name"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('seedsConfig.variety')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="variety_name"
                          v-model="varietyData.variety_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Variety (Bn)" vid="variety_name_bn" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="variety_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('seedsConfig.varietyBN')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="variety_name_bn"
                          v-model="varietyData.variety_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { varietyStore, varietyUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getVarietyData()
      this.varietyData = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      varietyData: {
        id: '',
        variety_name: '',
        variety_name_bn: '',
        crop_type_id: 0,
        crop_name_id: 0,
        status: '1',
        crop_name_bn: '',
        type_name: '',
        crop_name: ''
      }
    }
  },
  computed: {
    CropNameList: function () {
      if (this.varietyData.crop_type_id > 0) {
       return this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1 && item.crop_type_id === this.varietyData.crop_type_id)
      } else {
      return this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
      }
    },
    CropTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    getVarietyData () {
       const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      // this.varietyData.crop_type_id = this.varietyData.crop_type_id.value
      // this.varietyData.crop_name_id = this.varietyData.crop_name_id.value
      if (this.varietyData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${varietyUpdate}/${this.id}`, this.varietyData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, varietyStore, this.varietyData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
